.dashboard-content {
  background: #fff;
}

.dashboard-metrics-iframe {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.welcome-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}