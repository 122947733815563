.layout {
  min-height: 100vh;
}

.content {
  padding-top: 0vh !important;
}

.content h1 {
  text-align: center;
}

.login-form {
  margin-left: auto;
  margin-right: auto;
  max-width: 40vw;
}

.login-logo {
  max-height: 150px;
  text-align: center;
  padding: 20px;
}

.login-logo img {
  max-width: 100%;
}

@media screen and (max-width: 600px) {
  .login-form {
    max-width: 90vw;
  }
}