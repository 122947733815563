body {
  margin: 0;
}

.app-layout {
  min-height: 100vh;
}

.app-layout-header {
  padding: 0 0  
}

.app-layout-header-logo {
  float: left;
  margin: 15px 0 0 25px;
  color: #fff !important;
}

.app-layout-header-logo h2, .app-layout-header-logo h3 {
  color: #fff !important;
}

.app-layout-header-logo img {
  max-height: 64px;
  margin-top: -25px;
  padding: 5px;
}

.app-layout-header-menu {
  float: right;
  line-height: 64px;
  width: 120px;
}

.app-layout-header-menu-logout {
  float: right;
}

.app-layout-side-menu {
  height: 100%;
  border-right: 0;
}

.app-layout-main {
  padding: 24px;
}

@media only screen and (max-width: 600px) {
  .app-layout-main {
    padding: 10px;
  }

  .app-layout-side {
    position: absolute;
    z-index: 999;
  }
}
